/*----------------------------------------*/
/*  23 - Components - Instagram
/*----------------------------------------*/
.instagram-area{
    &.instagram-bg-1{
        background-image: url('../images/instagram/bg/1.jpg');
        background-repeat: no-repeat;
        background-position: right;
        background-size: cover;
        padding: 130px 0;
    }
    .container{
        @include breakpoint(min-xl_device){
            max-width: 1330px;
        }
    }
    .instagram-col-1{
        flex: 0 0 40%;
        max-width: 40%;
        @include breakpoint(max-xxs_device){
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
    .instagram-col-2{
        flex: 0 0 60%;
        max-width: 60%;
        @include breakpoint(max-xxs_device){
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
    .instagram-sub-col{
        flex: 0 0 33.333%;
        max-width: 33.333%;
    }
    .instagram-content{
        h2{
            &.heading{
                font-size: 46px;
                line-height: 64.4px;
                margin-bottom: 0;
                max-width: 60%;
                @include breakpoint (max-md_device){
                    font-size: 30px;
                    line-height: 1.4;
                }
                @include breakpoint(max-xs_device){
                    font-size: 25px;
                    text-transform: capitalize !important;
                }
                @include breakpoint(max-xxs_device){
                    max-width: 100%;
                    text-align: center;
                    padding-bottom: 20px;
                }
            }
        }
    }
    .instagram-img{
        margin: 5px;
        position: relative;
        &:before{
            background-color: rgba(84,89,95,0.6);
            content: "";
            width: 100%;
            height: 100%;
            opacity: 0;
            position: absolute;
            @extend %basetransition;
            cursor: pointer;
            pointer-events: none;
        }
        &:hover{
            &:before{
                opacity: 0.8;
            }
            .add-action{
                opacity: 1;
            }
        }
        .add-action{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            pointer-events: none;
            opacity: 0;
            @extend %basetransition;
            span,
            i{
                color: $white-color;
            }
        }
    }
}