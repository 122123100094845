/*----------------------------------------*/
/*  27 - Components - Testimonial
/*----------------------------------------*/
.testimonial-bg-1{
    background-image: url('../images/testimonial/bg/1.jpg');
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	padding: 130px 0;
}
.testimonial-item{
    .client-info{
        text-align: center;
        p{
            &.feedback{
                font-size: 28px;
                line-height: 44.8px;
                text-align: center;
                margin-bottom: 0;
                padding-bottom: 20px;
                @include breakpoint(max-xs_device){
                    font-size: 20px;
                    line-height: 1.8;
                }
            }
        }
        .img-area{
            display: flex;
            justify-content: center;
            padding-bottom: 10px;
            img{
                border-radius: 100%;
                width: 55px;
                height: 55px;
            }
        }
    }
}

/* ---Testimonial Area Two--- */
.testimonial-area-2{
    padding: 85px 0 145px;
    .custom-container{
        margin: 0 auto;
        @include breakpoint(min-xxl_device){
            max-width: 1170px;
        }
        @include breakpoint(max-xl_device){
            padding-left: 30px;
            padding-right: 30px;
        }
        @include breakpoint(max-xs_device){
            padding-left: 20px;
            padding-right: 20px;
        }
    }
    .slick-list {
        margin: 0 -15px;
        .slick-slide {
            padding: 0 15px 40px;
        }
    }
    .testimonial-item{
        padding-bottom: 40px;
        .client-info{
            text-align: left;
            border-top: 1px solid $border-color;
            border-left: 1px solid $border-color;
            border-right: 1px solid $border-color;
            padding: 45px 45px 0;
            position: relative;
            @include breakpoint(max-md_device) {
                padding: 25px 25px 0;
            }
            &:before {
                background-color: $border-color;
                content: "";
                width: 40px;
                height: 1px;
                position: absolute;
                bottom: 0;
                left: 0;
            }
            &:after {
                background-color: $border-color;
                content: "";
                width: calc(100% - 170px);
                height: 1px;
                position: absolute;
                bottom: 0;
                right: 0;
            }
            > p{
                &.feedback{
                    font-size: 22px;
                    text-align: left;
                    line-height: 39.6px;
                    position: relative;
                    padding-bottom: 15px;
                    margin-bottom: 20px;
                    @include breakpoint(max-lg_device){
                        font-size: 18px;
                        line-height: 32px;
                    }
                    &:before{
                        background-color: $border-color;
                        width: 100px;
                        height: 1px;
                        content: "";
                        position: absolute;
                        bottom: 0;
                        left: 0;
                    }
                }
            }
            > h6{
                &.client-name{
                    color: $primary-color;
                    font-size: 18px;
                    line-height: 32.4px;
                    padding-bottom: 20px;
                }
            }
            .img-area{
                justify-content: flex-start;
                padding-left: 15px;
                img{
                    width: 88px;
                    height: 88px;
                    margin-bottom: -50px;
                }
            }
        }
    }
}