/*----------------------------------------*/
/*  25 - Components - Shipping
/*----------------------------------------*/
.shipping-area{
    padding: 65px 0;
    .container{
        @include breakpoint(min-xl_device){
            max-width: 1300px;
        }
    }
    .shipping-item{
        text-align: center;
        .elementor-icon{
            color: $primary-color;
        }
        .shipping-content{
            padding-left: 15px;
            > h3{
                &.heading{
                    font-size: 18px;
                    line-height: 45px;
                    margin-bottom: 0;
                    @include breakpoint(max-lg_device){
                        font-size: 14px;
                    }
                }
            }
            > p{
                &.short-desc{
                    font-size: 15px;
                    margin-bottom: 0;
                }
            }
        }
    }
    [class*="col-"]{
        &:not(:last-child){
            @include breakpoint(max-md_device){
                padding-bottom: 30px;
            }
        }
    }
}